import { NavLink } from "react-router-dom";
import { BiRun } from "react-icons/bi";

import {
  AiOutlineHistory,
  AiOutlineHome,
  AiOutlineWallet,
} from "react-icons/ai";
import { BsPeople, BsQrCode } from "react-icons/bs";
import { useSelector } from "react-redux";

export const Menuitems = () => {
  const profiledetail = useSelector((state) => state.B2B.profileDetail);
  const list = [
    {
      key: "1",
      icon: <AiOutlineHome />,
      label: (
        <NavLink to={"/dashboard"}>
          <p>Dashboard</p>
        </NavLink>
      ),
    },
    {
      key: "14",
      icon: <BiRun />,
      label: (
        <NavLink to={"/express-payout"}>
          <p>Express Payout</p>
        </NavLink>
      ),
    },
    {
      key: "17",
      icon: <BsQrCode />,
      label: (
        <NavLink to={"/upi-dmt"}>
          <p>UPI DMT</p>
        </NavLink>
      ),
    },
    {
      key: "15",
      icon: <BsPeople />,
      label: (
        <NavLink to={"/memberList"}>
          <p>Members</p>
        </NavLink>
      ),
    },
    {
      key: "12",
      icon: <AiOutlineWallet />,
      label: (
        <NavLink to={"/Report/wallet"}>
          <p>Wallet</p>
        </NavLink>
      ),
    },
    {
      key: "11",
      icon: <AiOutlineHistory />,
      label: <p>Reports</p>,
      children: [
        {
          key: "11.11",
          label: (
            <NavLink to={"/Report/xpressPayout"}>
              <p>XpressPayout</p>
            </NavLink>
          ),
        },
        {
          key: "11.15",
          label: (
            <NavLink to={"/Report/upi-dmt"}>
              <p>Upi DMT</p>
            </NavLink>
          ),
        },
      ],
    },
  ];
  if (profiledetail.member_type?.toLowerCase() !== "Retailer".toLowerCase()) {
    return list;
  } else {
    const filter = list.filter((i) => i.key !== "15");
    return filter;
  }
};
