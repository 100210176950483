import { Get, Post, getAPIUrl } from "./apiMethod";
import { Authendpoint, Globalendpoints } from "./global";

export const userLogin = (data, param = "") => {
  const url = getAPIUrl(Authendpoint.Login, param);
  return Post(url, data, false);
};
export const checkPasswordStatus = (data, param = "") => {
  const url = getAPIUrl(Authendpoint.checkPasswordStatus, param);
  return Post(url, data, false);
};

export const sendPasswordOtp = (data, param = "") => {
  const url = getAPIUrl(Authendpoint.sendpasswordOtp, param);
  return Post(url, data, false);
};

export const setPAsswordAndPin = (data, param = "") => {
  const url = getAPIUrl(Authendpoint.setPasswordAndPin, param);
  return Post(url, data, false);
};
export const sendResetOtp = (data, param = "") => {
  const url = getAPIUrl(Authendpoint.sendResetPasswordOtp, param);
  return Post(url, data, false);
};
export const ResetPassword = (data, param = "") => {
  const url = getAPIUrl(Authendpoint.resetPassword, param);
  return Post(url, data, false);
};
export const ResetUserPin = (data, param = "") => {
  const url = getAPIUrl(Authendpoint.resetPin, param);
  return Post(url, data, false);
};
export const UserLogout = (data, param = "") => {
  const url = getAPIUrl(Authendpoint.Logout, param);
  return Post(url, data, false);
};
export const verifyPin = (data, param = "") => {
  const url = getAPIUrl(Authendpoint.VeriFyPin, param);
  return Post(url, data, false);
};
export const changePassword = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.changePassword, param);
  return Post(url, data);
};
export const changePin = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.changePin, param);
  return Post(url, data);
};
export const GetDashboardData = (param = "") => {
  const url = getAPIUrl(Globalendpoints.Dashboard, param);
  return Get(url);
};
export const getCountry = (param = "") => {
  const url = getAPIUrl(Globalendpoints.getCountry, param);
  return Get(url);
};
export const KYCStatus = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.checkKycStatus, param);
  return Post(url, data);
};
export const generateQrForUPI = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.generateQr, param);
  return Post(url, data);
};
export const checkUpiPaymentstatus = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.checkUpiPaymentstatus, param);
  return Post(url, data);
};
export const getState = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.getState, param);
  return Post(url, data);
};
export const getCity = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.getCity, param);
  return Post(url, data);
};
export const getBanks = (param = "") => {
  const url = getAPIUrl(Globalendpoints.getBanks, param);
  return Get(url);
};
export const getMemberServices = (param = "") => {
  const url = getAPIUrl(Globalendpoints.getMemberServices, param);
  return Get(url);
};
export const AddBankAccount = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.addBankAccount, param);
  return Post(url, data);
};
export const getmemberType = (param = "") => {
  const url = getAPIUrl(Globalendpoints.getmemberType, param);
  return Get(url);
};

export const getmemberPackages = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.getPackages, param);
  return Post(url, data);
};
export const MemberProfile = (param = "") => {
  const url = getAPIUrl(Globalendpoints.Profile, param);
  return Get(url);
};
export const GetWalletBalance = (param = "") => {
  const url = getAPIUrl(Globalendpoints.getwalletBalance, param);
  return Get(url);
};
// RRCHARGE API FUNCTIONS
export const GetOperatorAndCircle = (param = "") => {
  const url = getAPIUrl(Globalendpoints.operatorAndCircle, param);
  return Post(url);
};
export const GetPlans = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.rechargePlans, param);
  return Post(url, data);
};
export const GetNumberOperator = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.getOperatorAsNumber, param);
  return Post(url, data);
};
export const DoRecharge = (data, param = "", endpoint) => {
  const url = getAPIUrl(endpoint, param);
  return Post(url, data);
};
export const RechargeStatus = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.rechargeStatus, param);
  return Post(url, data);
};

export const getBbpsOperator = (param = "") => {
  const url = getAPIUrl(Globalendpoints.bbspsOperator, param);
  return Get(url);
};

export const getBbpsformParams = (param = "") => {
  const url = getAPIUrl(Globalendpoints.bbpsFormParams, param);
  return Get(url);
};
export const fetchBill = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.fetchBill, param);
  return Post(url, data);
};
export const payBill = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.paybill, param);
  return Post(url, data);
};
export const submitKyc = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.submitKyc, param);
  return Post(url, data);
};
// export const verifyKycOtp=(data,param="")=>{
//     const url=getAPIUrl(Globalendpoints.verifyKycOtp,param)
//     return Post(url,data)
// }
export const getRemitters = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.getRemitters, param);
  return Post(url, data);
};
export const addRemitters = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.AddRemiter, param);
  return Post(url, data);
};
export const verifyOtp = (data, param = "", endpoint) => {
  const url = getAPIUrl(endpoint, param);
  return Post(url, data);
};
export const getDmtBanks = (param = "") => {
  const url = getAPIUrl(Globalendpoints.dmtBanks, param);
  return Get(url);
};
export const addBenificiary = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.benificiaryReg, param);
  return Post(url, data);
};
export const verifyBankAccount = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.verifyBank, param);
  return Post(url, data);
};
export const verifyBenificiary = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.verifyBenificiary, param);
  return Post(url, data);
};
export const dmtFundTransfer = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.fundTransfer, param);
  return Post(url, data);
};
export const DmtAndPayoutTogether = (data, param = "", endpoint) => {
  const url = getAPIUrl(endpoint, param);
  return Post(url, data);
};
export const beneficiaryDelete = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.beneficiaryDelete, param);
  return Post(url, data);
};
export const verifyPayout = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.payoutVerification, param);
  return Post(url, data);
};
export const getPayoutBanks = (param = "") => {
  const url = getAPIUrl(Globalendpoints.getPayoutBanks, param);
  return Get(url);
};
export const getXpressBeneficiaries = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.getXpressBeneficiary, param);
  return Post(url, data);
};
export const XpressPayoutcheckstatus = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.checkStatus, param);
  return Post(url, data);
};
export const XpressPayout = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.XpressPAyout, param);
  return Post(url, data);
};
export const XpressPayoutOtp = (param = "") => {
  const url = getAPIUrl(Globalendpoints.OtpForExpress, param);
  return Get(url);
};
export const AddXpressDmtBank = (data,param = "") => {
  const url = getAPIUrl(Globalendpoints.saveXpressDmtBank, param);
  return Post(url,data);
};
export const utiLogin = (param = "") => {
  const url = getAPIUrl(Globalendpoints.utliLogin, param);
  return Get(url);
};
export const nsdlRequest = (param = "",data) => {
  const url = getAPIUrl(Globalendpoints.nsdlrequest, param);
  return Post(url,data);
};
export const checkPanStatus = (data,param = "") => {
  const url = getAPIUrl(Globalendpoints.checkPanStatus, param);
  return Post(url,data);
};
export const kycRequest = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.DigioKyc, param);
  return Post(url, data);
};
export const getAllAepsMasters = (param = "") => {
  const url = getAPIUrl(Globalendpoints.getMasters, param);
  return Get(url);
};
export const merchantOnboard = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.merchantOnboard, param);
  return Post(url, data);
};
export const AepsEkycSendOtp = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.sendOtp, param);
  return Post(url, data);
};
export const AepsKycValidateOtp = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.validateOtp, param);
  return Post(url, data);
};
export const AepsKycResnddOtp = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.resendOtp, param);
  return Post(url, data);
};
export const AepsKycBiometric = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.biometric, param);
  return Post(url, data);
};
export const AepsTwoFactorAuth = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.twoFactorauth, param);
  return Post(url, data);
};
export const AepsCashWithdrawl = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.cashwithdrawl, param);
  return Post(url, data);
};
export const AepsBalanceEnquiry = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.balanceEnquiry, param);
  return Post(url, data);
};
export const AepsAadharpay = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.aadharPay, param);
  return Post(url, data);
};
export const AepsMinistatement = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.miniStatement, param);
  return Post(url, data);
};

export const addMember = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.addmember, param);
  return Post(url, data);
};
export const MemberList = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.memberList, param);
  return Post(url, data);
};
export const SubmitFundRequest = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.submitFundRequest, param);
  return Post(url, data);
};
export const GetAdminBankDetail = (param = "") => {
  const url = getAPIUrl(Globalendpoints.adminBankDetail, param);
  return Get(url);
};
// REPORTS FUNCTIONS
export const getWalletReport = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.getWalletReport, param);
  return Post(url, data);
};
export const GetRechargeReport = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.rechargeReport, param);
  return Post(url, data);
};
export const getBbpsReport = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.bbpsreport, param);
  return Post(url, data);
};
export const getDmtReport = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.dmtReport, param);
  return Post(url, data);
};
export const AepsMinistatementReport = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.miniSattementReport, param);
  return Post(url, data);
};
export const AepsCashWithdrawlReport = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.cashwithdrawlReport, param);
  return Post(url, data);
};
export const AepsAadharpayReport = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.aadharpayReport, param);
  return Post(url, data);
};
export const payoutReport = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.payoutReport, param);
  return Post(url, data);
};
export const getUtiReport = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.utiReport, param);
  return Post(url, data);
};
export const getXpressPayoutReport = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.xpressPayoutReport, param);
  return Post(url, data);
};
export const getFunReqestReport = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.fundRequestReport, param);
  return Post(url, data);
};
export const getUpiReport = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.upiReport, param);
  return Post(url, data);
};
export const getBalanceReport = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.balanceEnquireyReport, param);
  return Post(url, data);
};
export const getNsdlReport = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.nsdlReport, param);
  return Post(url, data);
};
export const getUpiDmtReport = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.upiDmtReport, param);
  return Post(url, data);
};
export const UpiDmt = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.UpiDmt, param);
  return Post(url, data);
};
export const getPayoutAndDmtOtp = (param = "") => {
  const url = getAPIUrl(Globalendpoints.getTransactionOtp, param);
  return Get(url);
};


// YES BANK AEPS FUNCTIONS
export const YesBankAepsOboard = (data,param = "") => {
  const url = getAPIUrl(Globalendpoints.yesMerchantOnboard, param);
  return Post(url,data);
};
export const YesBankAepsValidateOtp = (data,param = "") => {
  const url = getAPIUrl(Globalendpoints.yesValidateOtp, param);
  return Post(url,data);
};
export const YesBankAepsBiometric = (data,param = "") => {
  const url = getAPIUrl(Globalendpoints.yesBiometric, param);
  return Post(url,data);
};
export const YesBankAepsTwoFA = (data,param = "") => {
  const url = getAPIUrl(Globalendpoints.yesTwoFactorAuth, param);
  return Post(url,data);
};
export const YesBankAepsGetWadh = (param = "") => {
  const url = getAPIUrl(Globalendpoints.yesGetAadharWadh, param);
  return Post(url);
};