import { Input } from 'antd';
import React from 'react'

const CommonInput = ({placeholder,value,maxlength=500,onInput=()=>{},onChange=()=>{},disabled=false,className="outline-none text-xs border w-full mb-2 border-gray-200 focus:border-primary  h-9 px-2"}) => {
  return (
    <Input
    placeholder={placeholder}
    autoComplete='off'
    value={value}
    disabled={disabled}
    maxLength={maxlength}
    onInput={onInput}
    onChange={onChange
    }
    className={className}
  />
  )
}

export default CommonInput