import { useState, useRef } from 'react';

const useOtpHook = (NoOfFields) => {
  const otpLength = NoOfFields;
  const inputRefs = Array.from({ length: otpLength }, () => useRef(null));
  const [pin, setPin] = useState("");

  const handleChange = (index, value) => {
    if (/^[0-9]*$/.test(value)) {
      setPin((prevOTP) => {
        const updatedOTP = [...prevOTP];
        updatedOTP[index] = value;
        return updatedOTP;
      });

      // Move to the next input field if the value is numeric
      if (value !== "" && index < otpLength - 1 && inputRefs[index + 1].current) {
        inputRefs[index + 1].current.focus();
      }
    }
  };

  const handleKeyUp = (index, event) => {
    if (event.keyCode === 8) {
      // Clear the previous input field on Backspace if the current field is empty
      if (index > 0 && inputRefs[index - 1].current) {
        setPin((prevOTP) => {
          const updatedOTP = [...prevOTP];
          updatedOTP.splice(index, 1);
          return updatedOTP;
        });

        // Move focus back to the previous input field
        inputRefs[index - 1].current.focus();
      }
    }
  };

  return {
    handleChange,
    handleKeyUp,
    pin,
    inputRefs,
  };
};

export default useOtpHook;
