import React, { useEffect, useState } from "react";
import { setselectedTab } from "../../../Store/B2bslice";
import { useDispatch } from "react-redux";

const Dashboard = () => {
 const dispatch=useDispatch()
useEffect(()=>{
  dispatch(setselectedTab("1"));

},[])
  return (
    <>
      <div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-4 place-items-start gap-3">
       <p>Dashboard</p>
      </div>

      {/* <div className="bg-white p-8 rounded-xl mt-8 grid grid-cols-1 md:grid-cols-2 gap-4 place-items-start">
        <div className="bg-[#5e72e4] h-[190px]  pl-8 relative animate-fade-in  w-full md:w-[90%] py-3 rounded-3xl ">
          <div className="text-white  mb-4">
            <img src={Images.circleShape} alt="circle" />
          </div>
          <p className="text-bswhite font-semibold text-2xl">
            Rs. {Balance ?? "-"}/-
          </p>
          <p className="text-white">Wallet Balance</p>
          <div
            onClick={handleGet}
            className="bg-white rounded-xl p-4 flex gap-4 cursor-pointer justify-around items-center absolute bottom-4 md:bottom-16 md:rotate-90 right-2 md:right-6"
          >
            <BiRefresh
              className={`${spinState ? "animate-spin" : ""}`}
              size={20}
            />
            <p className="text-md font-semibold ">Refresh</p>
          </div>
        </div>
    
        <div className="w-full">
          <p className="font-bold text-xl border-b-2 border-primary w-1/4">
            Services
          </p>
          <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 place-items-start gap-8 mt-2">
            {activeServices.map((i, index) => {
              return (
                <div
                  key={index}
                  className="flex justify-around items-center gap-3  "
                >
                  <AiFillCheckCircle size={20} color={ThemeColor.bsgreen} />
                  <Tooltip title={i}>
                  <p className="font-semibold capitalize">
                    {trimString(i, 12)}
                  </p>
                  </Tooltip>
                </div>
              );
            })}
            {inActiveServices.map((i, index) => {
              return (
                <div
                  key={index}
                  className="flex justify-around items-center gap-3  "
                >
                  <FcCancel size={20} color={ThemeColor.bsred} />
                  <Tooltip title={i}>
                  <p className="font-semibold capitalize">{i}</p>
                  </Tooltip>
                </div>
              );
            })}
          </div>
        </div>
      </div> */}
    </>
  );
};

export default Dashboard;
