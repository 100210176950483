import React, { useEffect } from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import ProjectLayout from "../ProjectLayout";
import { getLocalStorageItem } from "../Utils";
import { useSelector } from "react-redux";

function ProtectedAuth() {
  const location = useLocation();
  const profileDetail = useSelector((state) => state.B2B.profileDetail);
  const navigate = useNavigate();
  const isAuthenticated = () => {
    const user = getLocalStorageItem("isLoggedIn");
    return user;
  };

  return isAuthenticated() ? (
    <>
      {" "}
      <ProjectLayout>
          <Outlet />
      </ProjectLayout>
    </>
  ) : (
    <>
      <Navigate to="/" replace />
    </>
  );
}
export default ProtectedAuth;
