import jsPDF from "jspdf";
import { ToastContainer, toast } from "react-toastify";
import XMLParser from "react-xml-parser";
import { KYCStatus, MemberProfile } from "../ApiServices/Apifun";
import { setProfile } from "../Store/B2bslice";
import axios from "axios";
import { xml2json } from "./xml2json";

export const PortalName = "Pay Kuber";
// Get an item from local storage
export const getLocalStorageItem = (key) => {
  try {
    const item = localStorage.getItem(key);
    return item ? JSON.parse(item) : null;
  } catch (error) {
    console.error(`Error getting ${key} from local storage: ${error}`);
    return null;
  }
};

// Set an item in local storage
export const setLocalStorageItem = (key, value) => {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    console.error(`Error setting ${key} in local storage: ${error}`);
  }
};

// Remove an item from local storage
export const removeLocalStorageItem = (key) => {
  try {
    localStorage.removeItem(key);
  } catch (error) {
    console.error(`Error removing ${key} from local storage: ${error}`);
  }
};

//antd custom message function
export const messageConfiguration = (type, message, duration) => {
  return {
    type: type,
    content: message,
    style: { marginTop: "80px" },
    duration: duration,
  };
};

// custom react-toastify
export const dispatchtoast = (message, type) => {
  if (type) {
    return toast.error(message);
  }
  return toast.success(message);
};

// dynamic function to catch all errors and show on react toastify
export const showError = (error) => {
  if (typeof error === "object") {
    Object.entries(error).map(([key, value]) => {
      const errmessage = value;
      return dispatchtoast(errmessage, true);
    });
  } else {
    return dispatchtoast(error, true);
  }
};

// patterns for pin,aadhar,pan,email,mobile,gst
export const pincodePattern = /^[1-9][0-9]{5}$/; // Regular expression for valid PIN code format
export const aadharPattern = /^\d{12}$/; // Regular expression for valid Aadhaar card number format
export const panPattern = /^[A-Za-z]{5}\d{4}[A-Za-z]{1}$/; // Regular expression for PAN number format
export const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/; // Regular expression for email format
export const mobilePattern = /^[6-9]\d{9}$/; // Regular expression for valid mobile number format
export const gstPattern =
  /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/; // GST number pattern
export const ifscPattern = /^[A-Z]{4}0[A-Z0-9]{6}$/;
export const pancardValidation = (text) => {
  let regex = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
  if (regex.test(text)) {
    return true;
  }
  return false;
};
export const validateEmail = (email) => {
  const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
  return emailRegex.test(email);
};
export const validateAadharNumber = (number) => {
  const aadharRegex = /^\d{12}$/;
  if (aadharRegex.test(number)) {
    return true;
  }
  return false;
};

// function to convert rupee in number to rupeee in words
export const numberToWords = (num) => {
  const singleDigitWords = [
    "Zero",
    "One",
    "Two",
    "Three",
    "Four",
    "Five",
    "Six",
    "Seven",
    "Eight",
    "Nine",
  ];
  const twoDigitWords = [
    "Ten",
    "Eleven",
    "Twelve",
    "Thirteen",
    "Fourteen",
    "Fifteen",
    "Sixteen",
    "Seventeen",
    "Eighteen",
    "Nineteen",
  ];
  const tensWords = [
    "",
    "",
    "Twenty",
    "Thirty",
    "Forty",
    "Fifty",
    "Sixty",
    "Seventy",
    "Eighty",
    "Ninety",
  ];

  if (num < 0 || num >= 10000) {
    return "Number out of range";
  }

  if (num < 10) {
    return singleDigitWords[num] + " Only";
  }

  if (num >= 10 && num < 20) {
    return twoDigitWords[num - 10] + " Only";
  }

  if (num >= 20 && num < 100) {
    const tens = Math.floor(num / 10);
    const ones = num % 10;
    return (
      tensWords[tens] + (ones ? " " + singleDigitWords[ones] : "") + " Only"
    );
  }

  if (num >= 100 && num < 1000) {
    const hundreds = Math.floor(num / 100);
    const remaining = num % 100;
    return (
      singleDigitWords[hundreds] +
      " Hundred" +
      (remaining ? " " + numberToWords(remaining) : "")
    );
  }

  if (num >= 1000 && num < 10000) {
    const thousands = Math.floor(num / 1000);
    const remaining = num % 1000;
    return (
      singleDigitWords[thousands] +
      " Thousand" +
      (remaining ? " " + numberToWords(remaining) : "")
    );
  }
};

// function to convert xml to json
export const convertXmlToJson = (xmlString) => {
  var xml = new XMLParser().parseFromString(xmlString);
  return xml;
};

// function to open digio kyc window provided by pinwallet
// export function showkycWindow(reqid, mobile, access_token) {
//   setLocalStorageItem("startLoader", "1");
//   const generateRandom = Math.floor(Math.random() * 900) + 100;
//   var options = {
//     environment: "production",
//     Is_redirection_approach: true,
//     redirect_url:
//       "https://app.digio.in/#/gateway/login/" +
//       reqid +
//       "/" +
//       generateRandom +
//       "/" +
//       mobile +
//       "?redirect_url=https://app.pinwallet.in/api/partnerkyc/callback",
//     callback: function (response) {
//       // window.location.href = "/#/kycStatus";
//       KYCStatus({
//         request_id: reqid,
//       })
//         .then((res) => {
//           if (res.status) {
//             dispatchtoast(res.message);
//             window.location.href = "/#/kycStatus";
//           }
//         })
//         .catch((err) => console.log(err))
//         .finally(() => setLocalStorageItem("startLoader", "2"));
//       if (response.hasOwnProperty("error_code")) {
//         return console.log("error occurred in process");
//       }
//     },
//     logo: "https://app.pinwallet.in/assets/images/logo/pinwallet.png",
//     theme: {
//       primaryColor: "#2979c0",
//       secondaryColor: "#000000",
//     },
//   };
//   var digio = new Digio(options);
//   digio.init();
//   digio.submit(reqid, mobile, access_token);
//   //digio.cancel();
// }

// function to convert xml to json
// export function parseXml(xml) {
//   var dom = null;
//   if (window.DOMParser) {
//     try {
//       dom = new DOMParser().parseFromString(xml, "text/xml");
//     } catch (e) {
//       dom = null;
//     }
//   } else if (window.ActiveXObject) {
//     try {
//       dom = new ActiveXObject("Microsoft.XMLDOM");
//       dom.async = false;
//       if (!dom.loadXML(xml))
//         // parse error ..

//         window.alert(dom.parseError.reason + dom.parseError.srcText);
//     } catch (e) {
//       dom = null;
//     }
//   } else alert("cannot parse xml string!");
//   return dom;
// }

// all the device options name
export const deviceOptions = [
  {
    label: "MORPHO",
    value: "morpho",
  },
  {
    label: "MANTRA",
    value: "mantra",
  },
  {
    label: "MANTRA(IRIS)",
    value: "iris",
  },
  {
    label: "STARTEK",
    value: "startek",
  },
  // {
  //   label: "TATVIK",
  //   value: "tatvik",
  // },
  // {
  //   label: "DIGITAL_PERSONA",
  //   value: "digital_persona",
  // },
];

export const FingerNumber = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((i) => {
  return {
    label: i,
    value: i,
  };
});
// function to enable search in antd select by label
export const filterOption = (input, option) =>
  option.label?.toLowerCase().indexOf(input?.toLowerCase()) >= 0;

// function to define react-toastify
export const ToastContainerMsg = () => {
  return (
    <ToastContainer
      style={{
        fontSize: "12px",
      }}
      position="top-right"
      autoClose={3000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      // draggable
      // transition="flip"
      pauseOnHover
      theme="colored"
    />
  );
};

// LIST of all the services provided by B2B
export const AllServicesList = [
  { id: 1, service_name: "Recharge" },
  { id: 2, service_name: "BBPS" },
  { id: 3, service_name: "Xpress DMT" },
  { id: 4, service_name: "DMT" },
  { id: 5, service_name: "Payout" },
  { id: 6, service_name: "Prepaid Card" },
  { id: 7, service_name: "Wallet Transfer" },
  { id: 8, service_name: "AEPS" },
  { id: 9, service_name: "Xpress Payout" },
  { id: 10, service_name: "Credit Card" },
  { id: 11, service_name: "CMS" },
  { id: 12, service_name: "Hotel" },
  { id: 13, service_name: "Bus" },
  { id: 14, service_name: "Flight" },
  { id: 15, service_name: "Account Verification" },
  { id: 16, service_name: "UTI" },
  { id: 17, service_name: "Digio Kyc" },
  { id: 19, service_name: "Pan Services" },
];

// function to download html into PDF format
export const handledownloadPDF = (ref) => {
  const doc = new jsPDF({
    format: "a4",
    unit: "px",
  });
  // Adding the fonts
  doc.setFont("Inter-Regular", "normal");
  doc.html(ref.current, {
    async callback(doc) {
      await doc.save("document");
    },
  });
};

// function to get current date and time
export function getCurrentTime(date) {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  const dayOfWeek = days[date.getDay()];
  const dayOfMonth = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  const ampm = hours >= 12 ? "PM" : "AM";
  const formattedHours = hours % 12 || 12;

  const currentTimeString = `${dayOfWeek}, ${dayOfMonth} ${month}, ${year} ${
    formattedHours < 10 ? 0 + "" + formattedHours : formattedHours
  }:${minutes}:${seconds} ${ampm}`;

  return currentTimeString;
}

// global function to call profile again and again


export function getPosition(string, subString, index) {
  return string.split(subString, index).join(subString).length;
}
export const captureUrl = "https://localhost:11100/capture";

export const MorphpoPIDOPTS =
  '<PidOptions ver="1.0">' +
  '<Opts fCount="1" fType="2" iCount="" iType="" pCount="" pType="" format="0" pidVer="2.0" timeout="10000" env="P" otp="" wadh="" posh=""/>' +
  "</PidOptions>";
export const startekPIDOPTS =
  '<PidOptions ver="1.0">' +
  '<Opts fCount="1" fType="2" iCount="0" iType="0" pCount="0" pType="0" format="0" pidVer="2.0" timeout="10000"  env="P" otp="" wadh="" posh=""/>' +
  "</PidOptions>";
export const MantraPIDOPTS =
  '<?xml version="1.0"?> <PidOptions ver="1.0"> <Opts fCount="1" fType="2" iCount="0" pCount="0" format="0"   pidVer="2.0" timeout="10000" posh="UNKNOWN" env="P" /> </PidOptions>';
export const mantrairisPIDOPTS =
  '<?xml version="1.0"?> <PidOptions ver="1.0"> <Opts fCount="0" fType="0" iCount="1" pCount="0" pgCount="1" format="0"   pidVer="2.0" timeout="10000" pTimeout="20000" posh="UNKNOWN" env="P" /> <CustOpts><Param name="mantrakey" value="" /></CustOpts> </PidOptions>';

export function formatDateAndTime(dateTimeString) {
  // Parse the input date string
  const dateParts = dateTimeString.split(" ");
  const date = dateParts[0];
  const time = dateParts[1];

  const [day, month, year] = date.split("-");
  const [hour, minute] = time.split(":");
  // Create a Date object
  const parsedDate = new Date(year, month - 1, day, hour, minute);
  // Format the date in "DD-MM-YYYY hh:mm AM/PM" format
  const formattedDate = `${day}-${month}-${year} ${parsedDate.toLocaleString(
    "en-US",
    {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    }
  )}`;

  return formattedDate;
}
export const trimString = (string, limit = 25) => {
  const trim =
    string && string.length > limit
      ? string.substring(0, limit) + "..."
      : string;
  return trim;
};

export const serviceCategory = {
  Recharge: "1",
  BBPS: "2",
  XpressDMT: "3",
  DMT: "4",
  Payout: "5",
  PrepaidCard: "6",
  WalletTransfer: "7",
  AEPS: "8",
  XpressPayout: "9",
  CreditCard: "10",
  CMS: "11",
  Hotel: "12",
  Bus: "13",
  Flight: "14",
  AccountVerification: "15",
  UTI: "16",
  DigioKyc: "17",
  upi: "18",
};

export async function MantraDiscoverAvdm(callback) {
  var SuccessFlag = 0;
  var primaryUrl = "http://127.0.0.1:";
  try {
    var protocol = window.location.href;
    if (protocol.indexOf("https") >= 0) {
      primaryUrl = "https://127.0.0.1:";
    }
  } catch (e) {}

  for (var i = 11100; i <= 11120; i++) {
    if (primaryUrl == "https://127.0.0.1:") {
      i = "8005";
    }
    SuccessFlag = 0;
    var res;
    try {
      const response = await axios({
        method: "RDSERVICE", // Set the custom request method here
        url: primaryUrl + i.toString(),
        headers: {
          "Content-Type": "text/xml; charset=utf-8",
          Accept: "text/xml",
          origin: "https://localhost",
        },
      });
      const data = response.data;
      console.log({ data });
      res = { httpStaus: true, data: data };

      // finalUrl = primaryUrl + i.toString();

      var parser = new DOMParser();
      var xmlDoc = parser.parseFromString(data, "text/xml");
      var CmbData1 = xmlDoc
        .getElementsByTagName("RDService")[0]
        .getAttribute("status");
      var CmbData2 = xmlDoc
        .getElementsByTagName("RDService")[0]
        .getAttribute("info");
      if (RegExp("\\b" + "Mantra" + "\\b").test(CmbData2) == true) {
        var interfaces = xmlDoc.getElementsByTagName("Interface");
        if (interfaces[0].getAttribute("path") == "/rd/capture") {
          let MethodCapture = interfaces[0].getAttribute("path");
        }
        if (interfaces[1].getAttribute("path") == "/rd/capture") {
          let MethodCapture = interfaces[1].getAttribute("path");
        }
        if (interfaces[0].getAttribute("path") == "/rd/info") {
          let MethodInfo = interfaces[0].getAttribute("path");
        }
        if (interfaces[1].getAttribute("path") == "/rd/info") {
          let MethodInfo = interfaces[1].getAttribute("path");
        }
        SuccessFlag = 1;
        break;
      }
    } catch (error) {
      dispatchtoast(error, true);
    }
  }

  if (SuccessFlag == 0) {
    dispatchtoast("Connection failed Please try again.", true);
  } else if (SuccessFlag == 1) {
    callback();
  }

  return res;
}
// export function DeviceInfo(callback) {
//   var url = "https://localhost:11100/getDeviceInfo";
//   var successflag = 0;
//   axios({
//     method: "DEVICEINFO", // Set the custom request method here
//     url: url,
//     headers: {
//       "Content-Type": "text/xml",
//       Accept: "text/xml",
//       origin: "https://localhost",
//     },
//   })
//     .then((res) => {
//       const xmltojson = convertXmlToJson(res.data);
//       const checkDeviceConnection = Object.values(xmltojson.attributes).every(
//         (val) => val !== ""
//       );
//       if (checkDeviceConnection) {
//         callback();
//         successflag = 1;
//       } else {
//         dispatchtoast("Please connect device to scan fingerprint", true);
//       }
//     })
//     .catch((err) => dispatchtoast(err, true));
//   if (successflag === 0) {
//     dispatchtoast(
//       "Start the service from system services or reconnect device",
//       true
//     );
//   }
// }
// export async function StartekDeviceInfo(callback) {
//   var successflag = 0;
//   try {
//     // Make an Axios request to the DEVICEINFO endpoint
//     const response = await axios({
//       method: "DEVICEINFO",
//       url: `https://localhost:11200/rd/info`,
//       headers: {
//         "Content-Type": "text/xml; charset=utf-8",
//       },
//     });
//     if (response.status === 200) {
//       const parsed = parseXml(response.data);
//       const xmltojson = JSON.parse(xml2json(parsed).replace("undefined", ""));
//       if (xmltojson.DeviceInfo.additional_info.Param[0].value) {
//         successflag = 1;
//         callback(xmltojson.DeviceInfo.additional_info.Param[0].value);
//       } else {
//         dispatchtoast("Please connect device to scan fingerprint", true);
//       }
//     } else {
//       dispatchtoast("Unable to connect device", true);
//     }
//   } catch (error) {
//     dispatchtoast(
//       "Start the service from system services or reconnect device",
//       true
//     );
//   } finally {
//     if (successflag === 0) {
//       dispatchtoast(
//         "Start the service from system services or reconnect device",
//         true
//       );
//     }
//   }
// }
// export async function MantradeviceInfoAvdm(callback) {
//   try {
//     const XML =
//       '<?xml version="1.0"?> <PidOptions ver="1.0"> <Opts fCount="1" fType="2" iCount="0" pCount="0" format="0" pidVer="2.0" timeout="10000" posh="UNKNOWN" env="P" /> </PidOptions>';
//     for (let index = 11100; index < 11120; index++) {
//       try {
//         const discover = await axios({
//           method: "RDSERVICE",
//           url: `http://127.0.0.1:${index}/`,
//           headers: {
//             "Content-Type": "text/xml; charset=utf-8",
//           },
//           data: XML,
//         });
//         if (discover.status === 200) {
//           const response = await axios({
//             method: "DEVICEINFO",
//             url: `https://127.0.0.1:${index}/rd/info`,
//             headers: {
//               "Content-Type": "text/xml; charset=utf-8",
//             },
//             data: XML,
//           });
//           if (response.status === 200) {
//             console.log(response);
//             const parsed = parseXml(response.data);
//             const xmltojson = JSON.parse(
//               xml2json(parsed).replace("undefined", "")
//             );
//             console.log({ xmltojson });
//             if (xmltojson.DeviceInfo.additional_info.Param[0].value) {
//               callback(xmltojson.DeviceInfo.additional_info.Param[0].value,index);
//             } else {
//               dispatchtoast("Please connect device to scan fingerprint", true);
//             }
//           } else {
//             dispatchtoast("Unable to connect device", true);
//           }
//           break;
//         }
//       } catch (error) {
//         continue;
//       }
//     }

//     // console.log({discover})
//     // const response = await axios({
//     //   method: "DEVICEINFO",
//     //   url: `https://127.0.0.1:11101/rd/info`,
//     //   headers: {
//     //     "Content-Type": "text/xml; charset=utf-8",
//     //   },
//     //   data: XML,
//     // });
//     // if (response.status === 200) {
//     //   console.log(response)
//     //   const parsed = parseXml(response.data);
//     //   const xmltojson = JSON.parse(xml2json(parsed).replace("undefined", ""));
//     //   console.log({xmltojson})
//     //   if (xmltojson.DeviceInfo.additional_info.Param[0].value) {
//     //     callback(xmltojson.DeviceInfo.additional_info.Param[0].value);
//     //   } else {
//     //     dispatchtoast("Please connect device to scan fingerprint", true);
//     //   }
//     // } else {
//     //   dispatchtoast("Unable to connect device", true);
//     // }
//   } catch (error) {
//     console.log({ error });
//     dispatchtoast(
//       "Start the service from system services or reconnect device",
//       true
//     );
//   }
// }

// export async function MantraIrisdeviceInfoAvdm(callback) {
//   try {
//     const XML =
//       '<?xml version="1.0"?> <PidOptions ver="1.0"> <Opts fCount="0" fType="2" iCount="1" pCount="0" pgCount="1" format="0"   pidVer="2.0" timeout="10000" pTimeout="20000" posh="UNKNOWN" env="P" /> <CustOpts><Param name="mantrakey" value="" /></CustOpts> </PidOptions>';

//     // Make an Axios request to the DEVICEINFO endpoint
//     const response = await axios({
//       method: "DEVICEINFO",
//       url: `https://127.0.0.1:11100/rd/info`,
//       headers: {
//         "Content-Type": "text/xml; charset=utf-8",
//       },
//       data: XML,
//     });
//     if (response.status === 200) {
//       const parsed = parseXml(response.data);
//       const xmltojson = JSON.parse(xml2json(parsed).replace("undefined", ""));
//       if (xmltojson.DeviceInfo.additional_info.Param[0].value) {
//         callback(xmltojson.DeviceInfo.additional_info.Param[0].value);
//       } else {
//         dispatchtoast("Please connect device to scan fingerprint", true);
//       }
//     } else {
//       dispatchtoast("Unable to connect device", true);
//     }
//   } catch (error) {
//     dispatchtoast(
//       "Start the service from system services or reconnect device",
//       true
//     );
//   }
// }
// export const handleSubmitForAeps = (val, callback) => {
//   if (val.device === "morpho") {
//     DeviceInfo(() => callback(val));
//   } else if (val.device === "mantra") {
//     MantradeviceInfoAvdm((imei,index) => callback(val,index));
//   } else if (val.device === "iris") {
//     MantraIrisdeviceInfoAvdm(() => callback(val));
//   } else if (val.device === "startek") {
//     StartekDeviceInfo(() => callback(val));
//   }
// };
