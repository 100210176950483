
import Dashboard from "../Views/Main/Dashboard";
// import Busticket from "../Views/Main/OtherServices/Bus/Busticket";
// import Flights from "../Views/Main/OtherServices/Flights/Flight";
// import Hotel from "../Views/Main/OtherServices/Hotel/Hotel";
// import Verification from "../Views/Main/Payout/Verification";
import Profile from "../Views/Main/Profile";
// import RechargeAndBill from "../Views/Main/Recharge&Bill";
// import Uti from "../Views/Main/UTI";
import ProtectedAuth from "./PrivateAuth";
// import EkycIndex from "../Views/Main/BankingService/AEPSICICI/AepsEkyc/EkycIndex";
// import WithdrawalMoney from "../Views/Main/BankingService/AEPSICICI/AEPS/WithdrawalMoney";
import Addmembers from "../Views/Main/Members/Addmembers";
// import { AepsCashWithdrawl } from "../ApiServices/Apifun";
// import Ministatement from "../Views/Main/BankingService/AEPSICICI/AEPS/Ministatement";
// import AadharPay from "../Views/Main/BankingService/AEPSICICI/AEPS/AadharPay";
// import BalanceEnquiry from "../Views/Main/BankingService/AEPSICICI/AEPS/BalanceEnquiry";
// import FundRequest from "../Views/Main/FundRequest/FundRequest";
// import { Images } from "../Controller";
// import Default from "./404";
// import NotAvailable from "./NotAvailable";
// import CashWithdrawlReport from "../Views/Main/Reports/CashwithdrwalReport";
// import MiniStatementReport from "../Views/Main/Reports/MinistatementReport";
// import AadharPayReport from "../Views/Main/Reports/AadharPayRepoer";
// import PayoutReport from "../Views/Main/Reports/PayoutReport";
// import RechargeReport from "../Views/Main/Reports/RechargeReport";
// import BbpsReport from "../Views/Main/Reports/BbpsReports";
// import DmtReport from "../Views/Main/Reports/DmtReport";
// import UtiReport from "../Views/Main/Reports/UtiReport";
import WalletReport from "../Views/Main/Reports/WalletReport";
// import FundRequestReport from "../Views/Main/Reports/FundRequestReport";
// import Upi from "../Views/Main/UPI/Upi";
// import KycStatus from "../Views/Main/Dashboard/KycStatus";
// import ComingSoon from "./ComingSoon";
import MemberLists from "../Views/Main/Members/MemberList";
import UpiForm from "../Views/Main/UPIDMT/UpiForm";

import ExpressPayout from "../Views/Main/ExpressPAyout/ExpressPayout";
import XpressPayoutReport from "../Views/Main/Reports/XpressPayoutReport";
import UpiDmtReport from "../Views/Main/Reports/UpidmtReport";

// import BalanceEnquireyReport from "../Views/Main/Reports/BalanceEnquireyReport";
// import PanServices from "../Views/Main/PanServices";
// import NsdlReport from "../Views/Main/Reports/NsdlReport";
// import AepsYesOnboard from "../Views/Main/BankingService/AEPSYES/AEPSONBOARD";
// import YesWithdrawalMoney from "../Views/Main/BankingService/AEPSYES/AEPS/WithdrawalMoney";
// import YesMinistatement from "../Views/Main/BankingService/AEPSYES/AEPS/Ministatement";
// import YesBalanceEnquiry from "../Views/Main/BankingService/AEPSYES/AEPS/BalanceEnquiry";
// import YesBankBioMetric from "../Views/Main/BankingService/AEPSYES/AepsEkyc/EkycIndex";
// import YesBankAeps from "../Views/Main/BankingService/AEPSYES/AEPS/Aeps";

export const PrivateAuth = () => {
  return [
    {
      element: <ProtectedAuth />,
      children: [
        {
          path: "/Dashboard",
          element: <Dashboard />,
        },
        {
          path: "/express-payout",
          element: <ExpressPayout />,
        },
        {
          path: "/personal-info",
          element: <Profile />,
        },
        {
          path: "/Report/wallet",
          element: <WalletReport />,
        },
        {
          path: "/Report/xpressPayout",
          element: <XpressPayoutReport />,
        },
        {
          path: "/add-member",
          element: <Addmembers />,
        },
        {
          path: "/memberList",
          element: <MemberLists />,
        },
        {
          path: "/upi-dmt",
          element: <UpiForm />,
        },        {
          path: "/Report/upi-dmt",
          element: <UpiDmtReport />,
        },

        // ERROR ROUTES ENDED
      ],
    },
  ];
};
